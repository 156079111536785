import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyDR489dS1Bf-hbqB-UHnQFvyqjjpX6ObWY",
  authDomain: "crown-pointe-apartments.firebaseapp.com",
  databaseURL: "https://crown-pointe-apartments.firebaseio.com",
  projectId: "crown-pointe-apartments",
  storageBucket: "crown-pointe-apartments.appspot.com",
  messagingSenderId: "551793822015",
  appId: "1:551793822015:web:44dfb4ca9a65e580a0eb8a",
  measurementId: "G-R2R48PBM0V"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
